.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'] {
  background: var(--md-ref-palette-secondary90);
}

.MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true']:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    var(--md-ref-palette-secondary90);
}

/** Dark Theme */
.dark-theme .MuiAutocomplete-listbox .MuiAutocomplete-option {
  color: var(--md-sys-color-on-background-dark);
}
.dark-theme .MuiMenu-paper .MuiMenuItem-root:hover,
.dark-theme .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused,
.dark-theme .MuiAutocomplete-listbox .MuiAutocomplete-option:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.04),
      rgba(255, 255, 255, 0.04)
    ),
    var(--md-ref-palette-secondary30);
}
.dark-theme
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
.dark-theme
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true'],
.dark-theme
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true']:hover {
  color: var(--md-sys-color-on-background-dark);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.04),
      rgba(255, 255, 255, 0.04)
    ),
    var(--md-ref-palette-secondary30);
}
