.rich-text-content {
}
.rich-text-content p,
.rich-text-content ol,
.rich-text-content ul {
  margin-top: 8px;
  margin-bottom: 8px;
}
.rich-text-content > ol,
.rich-text-content > ul {
  padding-left: 16px;
}
.rich-text-content li {
  padding-bottom: 4px;
}
.rich-text-content li + ul,
.rich-text-content li + ol {
  padding-top: 0;
  margin-top: 0;
}
.rich-text-content :first-of-type {
  margin-top: 0;
}
.rich-text-content :last-child {
  margin-bottom: 0;
}
.rich-text-content .wysiwyg-mention {
  font-size: 14px;
  padding: 2px 2px;
  font-weight: 500;
  pointer-events: none;
  text-decoration: none;
  color: var(--md-ref-palette-tertiary40);
}

/**
 * Dark mode for rich editor
 */
.dark-theme .rich-text-content .wysiwyg-mention {
  color: var(--md-ref-palette-tertiary90);
}
