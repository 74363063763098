.intercom-page * {
  font-family: Lato, sans-serif;
}

/**
* Toggle the intercom launcher
*/
.hide-intercom-launcher
  .intercom-namespace:not(#intercom-positioner-tree):not(
    #intercom-tooltips-container
  ),
.hide-intercom-launcher .intercom-lightweight-app {
  opacity: 0;
  visibility: hidden;
}
.full-page-intercom-launcher .intercom-messenger-frame {
  left: 0;
  top: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  height: initial !important;
  max-height: initial !important;
  border-radius: 0 !important;
  transition: none !important;
  box-shadow: none !important;
}
.full-page-intercom-launcher .intercom-lightweight-app,
.full-page-intercom-launcher .intercom-launcher-frame,
.full-page-intercom-launcher .intercom-ytk0w4 {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.intercom-borderless-frame {
  display: none !important;
}

/** In Mobile Version */
.is-app-mobile.jobseekers-body.full-page-intercom-launcher
  .intercom-messenger-frame {
  left: 0;
  width: 100vw !important;
}
.is-app-mobile .intercom-messenger-frame:before {
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  z-index: 100;
  content: '';
  position: absolute;
  background-color: #3e59ff;
}
html.app-html.intercom-mobile-messenger-active,
body.app-body.intercom-mobile-messenger-active,
body.full-page-intercom-launcher.is-app-mobile {
  height: initial !important;
  width: initial !important;
  overflow: initial !important;
}
