/** Typography */
.dark-theme .MuiChip-label,
.dark-theme .MuiTypography-root,
.dark-theme .MuiSvgIcon-root,
.dark-theme .MuiFormHelperText-root {
  color: var(--md-sys-color-on-background-dark);
}

/** Divider */
.dark-theme .MuiDivider-root {
  border-color: var(--md-ref-palette-neutral-variant20);
}

/** Tabs */
.dark-theme .MuiTabs-indicator:before {
  background: var(--md-ref-palette-primary80);
}

/** Step Icon */
.dark-theme .MuiStepIcon-root.Mui-active {
  color: var(--md-ref-palette-primary80);
}
.dark-theme .MuiStepIcon-text {
  fill: var(--md-sys-color-background-dark);
}

/** Input */
.dark-theme .MuiInputBase-root {
  color: var(--md-sys-color-on-background-dark);
  background: var(--md-sys-color-background-dark);
}
.dark-theme .MuiOutlinedInput-notchedOutline {
  border-color: var(--md-ref-palette-neutral-variant20);
}

/** Input Label */
.dark-theme .MuiInputLabel-root {
  color: var(--md-ref-palette-neutral-variant80);
  background: var(--md-sys-color-background-dark);
}
.dark-theme .MuiInputLabel-root.Mui-focused {
  color: var(--md-ref-palette-neutral-variant90);
}

/** Autocomplete Popper */
.dark-theme .MuiAutocomplete-popper .MuiPaper-root,
.dark-theme .MuiMenu-paper,
.dark-theme .MuiPopover-paper {
  color: var(--md-sys-color-on-background-dark);
  background-color: var(--md-sys-color-background-dark);
}

/** Avatar */
.dark-theme .MuiAvatar-root {
  color: var(--md-sys-color-on-background-dark);
  background-color: var(--md-ref-palette-neutral30);
}

/** Circular Progress */
.dark-theme .MuiCircularProgress-svg {
  color: var(--md-sys-color-on-background-dark);
}

/** Backdrop */
/* .dark-theme .MuiModal-root:not(.MuiPopover-root) .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.9);
} */

/** Dialog */
.dark-theme .MuiDialog-root .MuiPaper-root {
  color: var(--md-sys-color-on-background-dark);
  background-color: var(--md-sys-color-background-dark);
}

/** Chip */
.dark-theme .MuiChip-root {
  background-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .MuiChip-root.MuiChip-clickable:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/** Link */
.dark-theme .MuiLink-root {
  color: var(--md-ref-palette-primary80);
}

/** Skeleton */
.dark-theme .MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.11);
}

/** Calendar */
.dark-theme .m3-datepicker-calendar-paper {
  color: var(--md-sys-color-on-background-dark);
  background-color: var(--md-sys-color-background-dark);
}
.dark-theme .m3-datepicker-calendar-paper > div {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}
.dark-theme
  .m3-datepicker-calendar-paper
  .MuiPickersArrowSwitcher-root
  .MuiPickersArrowSwitcher-button.Mui-disabled {
  opacity: 0.5;
}
.dark-theme
  .m3-datepicker-calendar-paper
  .MuiPickersDay-root:not(.Mui-selected):not(.MuiPickersDay-today) {
  color: var(--md-sys-color-on-background-dark);
  background-color: transparent;
}
.dark-theme
  .m3-datepicker-calendar-paper
  .MuiPickersDay-today:not(.Mui-selected) {
  color: var(--md-sys-color-on-background-dark);
  background-color: transparent;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.dark-theme
  .m3-datepicker-calendar-paper
  .MuiPickersDay-root.MuiPickersDay-today:hover {
  color: var(--md-sys-color-on-background-dark);
  background-color: rgba(255, 255, 255, 0.05);
}
.dark-theme
  .m3-datepicker-calendar-paper
  .MuiPickersDay-root:not(.Mui-selected):not(.MuiPickersDay-today):not(
    .Mui-disabled
  ):hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.dark-theme
  .m3-datepicker-calendar-paper
  .MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
  opacity: 0.5;
}

/** Tooltip */
.dark-theme .MuiTooltip-tooltip {
  color: var(--md-sys-color-on-background-light);
  background: var(--md-ref-palette-tertiary80);
}
